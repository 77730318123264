import React, {Component} from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from "@material-ui/core/Fade";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProductTab from './ProductTab';
import ProductPart from './ProductPart';
import selections from './selections';

const theme = createMuiTheme({
    palette : {
        primary : {main : '#cb9932'},
        secondary : {main : '#ffffff'}
    },
    typography : {
        useNextVariants : true
    }
});

let lang = document.documentElement.lang.split('-')[0].toUpperCase(),
    showLanguageBar = false;

class App extends Component {
    state = {
        isLoading : true,
        lang : 'DE',
        showProductParts : true,
        woodSelectionIndex : 0,
        rootSelectionIndex : 0,
        cushionSelectionIndex : 0,
        tableSelectionIndex : 0,
        parasolHolderSelectionIndex : 0,
        leftSideSelectionIndex : 0,
        rightSideSelectionIndex : 0,
        tabSelectionIndex : 0
    };

    productPartsTimeout = null;

    startProductPartsTimeout = () => {
        if (this.productPartsTimeout === null) {
            this.setState({
                showProductParts : false
            });
            this.productPartsTimeout = setTimeout(() => {
                this.productPartsTimeout = null;
                this.setState({
                    showProductParts : true
                });
            }, 250);
        }
    };

    onWoodSelectionChanged = (index) => {
        setTimeout(() => {
            this.setState({
                woodSelectionIndex : index
            });
            this.startProductPartsTimeout();
        }, 250);
    };

    onRootSelectionChanged = (index) => {
        setTimeout(() => {
            this.setState({
                rootSelectionIndex : index
            });
            this.startProductPartsTimeout();
        }, 250);
    };

    onCushionSelectionChanged = (index) => {
        setTimeout(() => {
            this.setState({
                cushionSelectionIndex : index
            });
            this.startProductPartsTimeout();
        }, 250);
    };

    onTableSelectionChanged = (index) => {
        setTimeout(() => {
            let parasolHolderSelectionIndex = this.state.parasolHolderSelectionIndex;
            if ((index === 1 || index === 3) && parasolHolderSelectionIndex === 0) {
                parasolHolderSelectionIndex = 1;
            }
            this.setState({
                tableSelectionIndex : index,
                parasolHolderSelectionIndex
            });
            this.startProductPartsTimeout();
        }, 250);
    };

    onParasolHolderSelectionChanged = (index) => {
        setTimeout(() => {
            let tableSelectionIndex = this.state.tableSelectionIndex;
            if (index === 0 && tableSelectionIndex === 1) {
                tableSelectionIndex = 0;
            }
            if (index === 0 && tableSelectionIndex === 3) {
                tableSelectionIndex = 2;
            }
            this.setState({
                parasolHolderSelectionIndex : index,
                tableSelectionIndex
            });
            this.startProductPartsTimeout();
        }, 250);
    };

    onTabSelectionChanged = (event, index) => this.setState({tabSelectionIndex : index});

    onLangChanged = (lang) => this.setState({lang});

    componentDidMount() {
        let self = this;
        document.onreadystatechange = function () {
            if (document.readyState === 'complete') {
                setTimeout(function () {
                    if (document.readyState === 'complete') {
                        self.setState({
                            isLoading : false,
                            lang
                        });
                    }
                }, 500);
            }
        }
    }

    render() {
        let imageNamePrefix = '';
        switch (this.state.woodSelectionIndex) {
            default:
            case 0:
                imageNamePrefix = 'Kebony';
                break;
            case 1:
                imageNamePrefix = 'Laerche';
                break;
            case 2:
                imageNamePrefix = 'Zeder';
                break;
            case 3:
                imageNamePrefix = 'Laerche';
                break;
        }

        return (
            <MuiThemeProvider theme={theme}>
                {
                    this.state.isLoading &&
                    <Card style={{
                        width : '100%',
                        maxWidth : 1120,
                    }}>
                        <CardContent>
                            <CircularProgress color="primary" style={{
                                position : 'relative',
                                left : '50%',
                                marginLeft : -24,
                                marginTop : 8
                            }}/>
                        </CardContent>
                    </Card>
                }
                {
                    !this.state.isLoading &&
                    <Card style={{
                        maxWidth : 1120,
                    }}>
                        <CardMedia style={{
                            height : 0,
                            paddingBottom : '68.21%',
                            marginLeft : '-5%',
                            marginRight : '5%',
                            marginTop : '-10%',
                            marginBottom : '-6%'
                        }} image="/configurator/Background.png">
                            <Fade in={this.state.showProductParts} timeout={{enter : 800, exit : 50}}>
                                <div style={{position : 'relative'}}>
                                    <ProductPart
                                        show={this.state.rootSelectionIndex === 0 && this.state.showProductParts}
                                        timeout={{enter : 100, exit : 100}}
                                        imageName="FussRund"/>
                                    <ProductPart
                                        show={this.state.rootSelectionIndex === 1 && this.state.showProductParts}
                                        timeout={{enter : 100, exit : 100}}
                                        imageName="FussQuadratisch"/>

                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 0 && (this.state.tableSelectionIndex === 2 || this.state.tableSelectionIndex === 3) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="KebonyTischHinten"/>
                                    <ProductPart
                                        show={(this.state.woodSelectionIndex === 1 || this.state.woodSelectionIndex === 3) && (this.state.tableSelectionIndex === 2 || this.state.tableSelectionIndex === 3) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="LaercheTischHinten"/>
                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 2 && (this.state.tableSelectionIndex === 2 || this.state.tableSelectionIndex === 3) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="ZederTischHinten"/>

                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 0 && this.state.cushionSelectionIndex === 0 && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="KebonyBasis"/>
                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 0 && this.state.cushionSelectionIndex === 1 && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="KebonyBasisPolsterauflage"/>
                                    <ProductPart
                                        show={(this.state.woodSelectionIndex === 1 || this.state.woodSelectionIndex === 3) && this.state.cushionSelectionIndex === 0 && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="LaercheBasis"/>
                                    <ProductPart
                                        show={(this.state.woodSelectionIndex === 1 || this.state.woodSelectionIndex === 3) && this.state.cushionSelectionIndex === 1 && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="LaercheBasisPolsterauflage"/>
                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 2 && this.state.cushionSelectionIndex === 0 && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="ZederBasis"/>
                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 2 && this.state.cushionSelectionIndex === 1 && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="ZederBasisPolsterauflage"/>

                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 0 && (this.state.parasolHolderSelectionIndex === 1) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="KebonySonnenschirmhalterung"/>
                                    <ProductPart
                                        show={(this.state.woodSelectionIndex === 1 || this.state.woodSelectionIndex === 3) && (this.state.parasolHolderSelectionIndex === 1) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="LaercheSonnenschirmhalterung"/>
                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 2 && (this.state.parasolHolderSelectionIndex === 1) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="ZederSonnenschirmhalterung"/>

                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 0 && (this.state.tableSelectionIndex === 1 || this.state.tableSelectionIndex === 3) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="KebonyTischVorne"/>
                                    <ProductPart
                                        show={(this.state.woodSelectionIndex === 1 || this.state.woodSelectionIndex === 3) && (this.state.tableSelectionIndex === 1 || this.state.tableSelectionIndex === 3) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="LaercheTischVorne"/>
                                    <ProductPart
                                        show={this.state.woodSelectionIndex === 2 && (this.state.tableSelectionIndex === 1 || this.state.tableSelectionIndex === 3) && this.state.showProductParts}
                                        timeout={{enter : 250, exit : 100}}
                                        imageName="ZederTischVorne"/>
                                </div>
                            </Fade>
                        </CardMedia>
                        <CardContent>
                            <Tabs
                                value={this.state.tabSelectionIndex}
                                onChange={this.onTabSelectionChanged}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                className="tabs"
                            >
                                <Tab label={selections.wood.label[this.state.lang]}/>
                                <Tab label={selections.root.label[this.state.lang]}/>
                                <Tab label={selections.cushion.label[this.state.lang]}/>
                                <Tab label={selections.table.label[this.state.lang]}/>
                                <Tab label={selections.parasolHolder.label[this.state.lang]}/>
                                <Tab label={selections.locker.label[this.state.lang]}/>
                            </Tabs>
                            {this.state.tabSelectionIndex === 0 &&
                            <ProductTab lang={this.state.lang} selection={selections.wood}
                                        selectionIndex={this.state.woodSelectionIndex}
                                        onSelectionChanged={this.onWoodSelectionChanged}/>}
                            {this.state.tabSelectionIndex === 1 &&
                            <ProductTab lang={this.state.lang} selection={selections.root}
                                        selectionIndex={this.state.rootSelectionIndex}
                                        onSelectionChanged={this.onRootSelectionChanged}/>}
                            {this.state.tabSelectionIndex === 2 &&
                            <ProductTab lang={this.state.lang} selection={selections.cushion}
                                        selectionIndex={this.state.cushionSelectionIndex}
                                        onSelectionChanged={this.onCushionSelectionChanged}
                                        imageNamePrefix={imageNamePrefix}/>}
                            {this.state.tabSelectionIndex === 3 &&
                            <ProductTab lang={this.state.lang} selection={selections.table}
                                        selectionIndex={this.state.tableSelectionIndex}
                                        onSelectionChanged={this.onTableSelectionChanged}
                                        imageNamePrefix={imageNamePrefix}/>}
                            {this.state.tabSelectionIndex === 4 &&
                            <ProductTab lang={this.state.lang} selection={selections.parasolHolder}
                                        selectionIndex={this.state.parasolHolderSelectionIndex}
                                        onSelectionChanged={this.onParasolHolderSelectionChanged}
                                        imageNamePrefix={imageNamePrefix}/>}
                            {this.state.tabSelectionIndex === 5 &&
                            <ProductTab lang={this.state.lang} selection={selections.locker}
                                        imageNamePrefix={imageNamePrefix}
                                        galleryImageNames={['Schliessfach1', 'Schliessfach2']}/>}
                        </CardContent>
                    </Card>
                }
                {
                    !this.state.isLoading && showLanguageBar &&
                    <div>
                        <Button color={this.state.lang === 'DE' ? 'primary' : 'default'}
                                onClick={() => this.onLangChanged('DE')}>DE</Button>
                        <Button color={this.state.lang === 'EN' ? 'primary' : 'default'}
                                onClick={() => this.onLangChanged('EN')}>EN</Button>
                        <Button color={this.state.lang === 'NL' ? 'primary' : 'default'}
                                onClick={() => this.onLangChanged('NL')}>NL</Button>
                        <Button color={this.state.lang === 'DA' ? 'primary' : 'default'}
                                onClick={() => this.onLangChanged('DA')}>DA</Button>
                        <Button color={this.state.lang === 'HU' ? 'primary' : 'default'}
                                onClick={() => this.onLangChanged('HU')}>HU</Button>
                        <Button color={this.state.lang === 'SV' ? 'primary' : 'default'}
                                onClick={() => this.onLangChanged('SV')}>SV</Button>
                        <Button color={this.state.lang === 'RU' ? 'primary' : 'default'}
                                onClick={() => this.onLangChanged('RU')}>RU</Button>
                    </div>
                }
            </MuiThemeProvider>
        );
    }
}

export default App;
