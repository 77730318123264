/**
 * @author dynamitec
 */

import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class ProductTab extends React.Component {
    onSelectionChanged = (index) => {
        this.setState({
            selectionOpen : false
        });
        this.props.onSelectionChanged(index);
    };

    render() {
        let {lang, selection, selectionIndex, imageNamePrefix, galleryImageNames} = this.props;
        if (!imageNamePrefix) {
            imageNamePrefix = '';
        }
        let name = '',
            description = '';
        if (selectionIndex !== undefined) {
            if (selection.options[selectionIndex].name) {
                name = selection.options[selectionIndex].name[lang];
            }
            if (selection.options[selectionIndex].description) {
                description = selection.options[selectionIndex].description[lang];
            }
        }
        if (selection.common) {
            if (selection.common.name) {
                name = selection.common.name[lang];
            }
            if (selection.common.description) {
                description = selection.common.description[lang];
            }
        }

        return (
            <Grid container spacing={8} justify="center">
                {selection.options.length > 1 && selection.options.map((option, index) => (
                    <Grid key={option.name.EN} item xs={12} sm={6} md={3} lg={3} xl={3} style={{paddingTop : 12}}>
                        <Button
                            onClick={(index !== selectionIndex) ? () => this.onSelectionChanged(index) : null}
                            fullWidth
                            style={{
                                marginTop : 12,
                                backgroundColor : (index === selectionIndex) ? 'rgba(203,150,53,0.15)' : ''
                            }}
                        >
                            <div style={{width : '100%', textAlign : 'center'}}>
                                <img src={'/configurator/Icon/' + imageNamePrefix + option.imageName + '.png'} alt=""
                                     style={{
                                         width : 250,
                                         height : 125,
                                         display : 'block',
                                         marginLeft : 'auto',
                                         marginRight : 'auto'
                                     }}/>
                                {option.name[lang]}
                            </div>
                        </Button>
                    </Grid>
                ))}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{marginTop : 48}}>
                    <Typography component="h2" variant="h6" style={{color : '#686868', textAlign : 'center'}}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={16} md={6} lg={6} xl={6} style={{paddingTop : 12}}>
                    <Typography component="div" variant="body1"
                                style={{
                                    color : '#686868',
                                    textAlign : 'center',
                                    fontWeight : 300,
                                    fontSize : 20
                                }}>
                        {description}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}/>
                {galleryImageNames !== undefined && galleryImageNames.map((imageName) => (
                    <Grid key={imageName} item xs={12} sm={6} md={3} lg={3} xl={3} style={{paddingTop : 12}}>
                        <div style={{width : '100%'}}>
                            <img src={'/configurator/Gallery/' + imageName + '.jpg'} alt=""
                                 style={{
                                     width : 227,
                                     height : 151,
                                     display : 'block',
                                     marginLeft : 'auto',
                                     marginRight : 'auto'
                                 }}/>
                        </div>
                    </Grid>
                ))}
            </Grid>
        );
    }
}

export default ProductTab;
