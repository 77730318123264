/**
 * @author dynamitec
 */

import React from 'react';
import Fade from "@material-ui/core/Fade";

class ProductPart extends React.Component {
    render() {
        return (
            <Fade in={this.props.show} timeout={this.props.timeout}>
                <div style={{
                    position : 'absolute',
                    top : 0,
                    left : 0,
                    width : '100%'
                }}>
                    <img src={'/configurator/' + this.props.imageName + '.png'} alt=""
                         style={{width : '100%', height : '100%'}}/>
                </div>
            </Fade>
        );
    }
}

export default ProductPart;
