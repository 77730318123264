import React from "react";

/**
 * @author dynamitec
 */

const selections = {
    wood : {
        label : {
            DE : 'Holzart',
            EN : 'Type of wood',
            NL : 'Houtsoort',
            DA : 'Type træ',
            HU : 'Fafajta',
            SV : 'Typ av trä',
            RU : 'Породы дерева'
        },
        options : [
            {
                imageName : 'KebonyBasis',
                name : {
                    DE : 'Kebony',
                    EN : 'Kebony',
                    NL : 'Kebony',
                    DA : 'Kebony',
                    HU : 'Kebony',
                    SV : 'Kenony',
                    RU : 'Кебони'
                },
                description : {
                    DE :
                        <div>
                            <ul>
                                <li>Hochwertiges Echtholz, zellimpregniert durch spezielle Technologie aus Norwegen</li>
                                <li>Schokoladenbraune Farbe</li>
                                <li>30 Jahre Garantie auf Verrottung</li>
                                <li>Aus nachhaltigem Plantagenholz – kein Tropenholz!</li>
                                <li>Härtegrad auf dem Niveau der besten Tropenhölzer</li>
                                <li>Sicher und ungiftig</li>
                                <li>Widerstandsfähig</li>
                            </ul>
                            Mehr dazu auf: <a href="https://www.kebony.de" target="_blank"
                                              rel="noopener noreferrer">www.kebony.de</a>
                        </div>,
                    EN :
                        <div>
                            <ul>
                                <li>real wood with enhanced and strengthened cell structure from Norway</li>
                                <li>chocolate brown color</li>
                                <li>outdoor life time warranty against rot for 30 years (!)</li>
                                <li>certified non-tropical hardwood</li>
                                <li>hardness level increased to levels of the best hardwoods</li>
                                <li>safe and toxin-free</li>
                                <li>high resistance against rot, fungi and other wood destroying micro-organisms</li>
                            </ul>
                            For more info: <a href="https://www.kebony.com/en" target="_blank"
                                              rel="noopener noreferrer">www.kebony.com</a>
                        </div>,
                    NL :
                        <div>
                            <ul>
                                <li>Hoogwaardige houtsoort, waarbij cellen middels een speciale technologie uit
                                    Noorwegen geïmpregneerd worden
                                </li>
                                <li>Chocoladebruine kleur</li>
                                <li>30 Jaar garantie op houtrot</li>
                                <li>Uit duurzaam plantagehout – geen tropenhout!</li>
                                <li>Hardheidsniveau stijgt boven tropisch hardhout uit</li>
                                <li>Zeker en niet giftig</li>
                                <li>Resistent</li>
                            </ul>
                            Voor meer: <a href="https://www.kebony.com/en" target="_blank"
                                          rel="noopener noreferrer">www.kebony.com</a>
                        </div>,
                    DA : 'Beskrivelse tekst er i gang...',
                    HU :
                        <div>
                            <ul>
                                <li>Kiváló minőségű természetes fa, Norvégiából származó speciális technológiával
                                    sejtimpregnált
                                </li>
                                <li>Csokoládé barna színű</li>
                                <li>30 év garancia a rothadásra</li>
                                <li>A fenntartható ültetvényes fa – nem a trópusokról származik!</li>
                                <li>keménységi mutatója a legjobb trópusi fák szintjén van</li>
                                <li>Biztonságos és nem mérgező</li>
                                <li>Keményfa, nagy ellenállóság</li>
                            </ul>
                            További információ: <a href="https://www.kebony.com/en" target="_blank"
                                                   rel="noopener noreferrer">www.kebony.com</a>
                        </div>,
                    SV : 'Beskrivningstekst pågår...',
                    RU :
                        <div>
                            <ul>
                                <li>высококачественное натуральное дерево, подвергнутое глубинной пропитке по
                                    специальной технологии из Норвегии
                                </li>
                                <li>цвет: шоколадно-коричневый</li>
                                <li>30-летняя гарантия от гниения</li>
                                <li>выращивается на лесосырьевых плантациях - не является экзотической породой</li>
                                <li>твёрдость на уровне лучших пород тропического дерева</li>
                                <li>безопасное и нетоксичное</li>
                                <li>высокая износостойкость</li>
                            </ul>
                            Подробнее на <a href="https://www.kebony.com/en" target="_blank"
                                            rel="noopener noreferrer">www.kebony.com</a>
                        </div>
                }
            }, {
                imageName : 'LaercheBasis',
                name : {
                    DE : 'Lärche',
                    EN : 'Larch',
                    NL : 'Lariks',
                    DA : 'Lærk',
                    HU : 'Vörösfenyő',
                    SV : 'Lärk',
                    RU : 'Лиственница'
                },
                description : {
                    DE :
                        <div>
                            <ul>
                                <li>Natürliches Lärchenholz</li>
                                <li>Helles, astiges Holz mit rustikaler Optik</li>
                                <li>mittelschwere Holzart, vergleichsweise hart</li>
                                <li>schwindet wenig und zeichnet sich durch ein gutes Stehvermögen aus</li>
                                <li>gute Festigkeits- und Elastizitätseigenschaften</li>
                                <li>gute Resistenz gegen Pilze und Insekten</li>
                                <li>der Witterung ausgesetzt zeigt es eine gute Dauerhaftigkeit</li>
                            </ul>
                        </div>,
                    EN :
                        <div>
                            <ul>
                                <li>natural larch wood</li>
                                <li>aesthetically beautiful timber with bright color</li>
                                <li>high density (41 lbs/ft3)</li>
                                <li>resins and extractives in Larch act as natural antiseptic against insects, decay and
                                    rot
                                </li>
                                <li>hard wood (1,100 lbs/in2 as measured by the Janka Scale)</li>
                                <li>sustainable recourse</li>
                                <li>low maintanance</li>
                            </ul>
                        </div>,
                    NL :
                        <div>
                            <ul>
                                <li>Natuurlijk larikshout</li>
                                <li>Blank, knoestig hout met een rustieke optiek</li>
                                <li>Middelzware hardhoutsoort</li>
                                <li>Krimpt weinig en zeer duurzaam</li>
                                <li>Goede kracht- en elasticiteitseigenschappen</li>
                                <li>Goede weerstand tegen schimmel en insekten</li>
                                <li>Onder zware weersomstandigheden vertoont het hout een prima stabiliteit</li>
                            </ul>
                        </div>,
                    DA : 'Beskrivelse tekst er i gang...',
                    HU :
                        <div>
                            <ul>
                                <li>Természetes vörösfenyőfa</li>
                                <li>Világos, csomós fa rusztikus megjelenéssel</li>
                                <li>középnehéz fa, viszonylag kemény</li>
                                <li>kevésbé zsugorodik, kitűnő időtállóság</li>
                                <li>kitűnő szilárdsági és rugalmassági tulajdonságokkal bír</li>
                                <li>kitűnő ellenállás a gombákkal és rovarokkal szemben</li>
                                <li>az időjárásnak kitéve jó tartósságot mutat</li>
                            </ul>
                        </div>,
                    SV : 'Beskrivningstekst pågår...',
                    RU :
                        <div>
                            <ul>
                                <li>очень ценная порода</li>
                                <li>светлое, малосучковатоеватое дерево с рустикальной текстурой</li>
                                <li>тяжёлый, твёрдый и плотный материал</li>
                                <li>хорошие прочностные и эластичные свойства</li>
                                <li>высокая устойчивость к грибкам и вредоносными насекомым</li>
                                <li>хорошая устойчивость к атмосферным воздействиям</li>
                            </ul>
                        </div>
                }
            }, {
                imageName : 'ZederBasis',
                name : {
                    DE : 'Zeder',
                    EN : 'Cedar',
                    NL : 'Ceder',
                    DA : 'Cedertræ',
                    HU : 'Cédrus',
                    SV : 'Cederträ',
                    RU : 'Кедр'
                },
                description : {
                    DE :
                        <div>
                            <ul>
                                <li>hellbraune bis rötliche Holzart</li>
                                <li>leicht, weich und dichtfaserig</li>
                                <li>trocknet rasch</li>
                                <li>weist eine gute Dauerhaftigkeit auf</li>
                                <li>sehr witterungsbeständig</li>
                                <li>gute Resistenz gegen Pilze und Insekten</li>
                            </ul>
                        </div>,
                    EN :
                        <div>
                            <ul>
                                <li>light brown to reddish coloured wood</li>
                                <li>light, soft and tightly fibrou</li>
                                <li>dries quickly</li>
                                <li>shows a good durability</li>
                                <li>weatherproof</li>
                                <li>good resistance to fungus and insects</li>
                            </ul>
                        </div>,
                    NL :
                        <div>
                            <ul>
                                <li>lichtbruine tot roodgekleurde houtsoort</li>
                                <li>de vezel is licht, zacht en dicht</li>
                                <li>droogt snel</li>
                                <li>hoge duurzaamheid</li>
                                <li>weerbestendig</li>
                                <li>goed bestand tegen schimmels en insekten</li>
                            </ul>
                        </div>,
                    DA :
                        <div>
                            <ul>
                                <li>Lysebrunt til rødligfarvet træ</li>
                                <li>Let, blødt og solidt træ</li>
                                <li>Tørrer hurtigt</li>
                                <li>Viser god holdbarhed</li>
                                <li>Vejrtæt</li>
                                <li>God modstand til svamp og insekter</li>
                            </ul>
                        </div>,
                    HU :
                        <div>
                            <ul>
                                <li>világosbarna vagy vöröses fa</li>
                                <li>könnyű, lágy és sűrű</li>
                                <li>gyorsan szárad</li>
                                <li>jó tartóssággal rendelkezik</li>
                                <li>nagyon időálló</li>
                                <li>jó ellenállás a gombák és rovarok ellen</li>
                            </ul>
                        </div>,
                    SV :
                        <div>
                            <ul>
                                <li>Ljusbrunt till rödaktigt träslag med aromatisk doft</li>
                                <li>Lätt, ganska mjukt men med mycket god densitet</li>
                                <li>Torkar snabbt</li>
                                <li>Formstabilt och mycket hållbart</li>
                                <li>God resistens mot röta, svampangrepp och insekter.</li>
                            </ul>
                        </div>,
                    RU :
                        <div>
                            <ul>
                                <li>цвет от светло коричневого до тёмно-розового</li>
                                <li>древесина лёгкая, мягкая, среднеплотная</li>
                                <li>быстро сохнет</li>
                                <li>обладает хорошей прочностью</li>
                                <li>высокая стойкость к атмосферным воздействиям и долговечность</li>
                                <li>высокая устойчивость к грибкам и вредоносными насекомым</li>
                            </ul>
                        </div>
                }
            }, {
                imageName : 'LaercheBasis',
                name : {
                    DE : 'Zirbe',
                    EN : 'Stone pine',
                    NL : 'Steen grenen',
                    DA : 'Sten fyr',
                    HU : 'Mandulafenyő',
                    SV : 'Sten tall',
                    RU : 'Кедровая сосна'
                },
                description : {
                    DE :
                        <div>
                            <ul>
                                <li>Robustes Holz mit stark duftenden ätherischen Ölen</li>
                                <li>Die Zirbe behandelt man einmal jährlich mit Zirbenöl</li>
                                <li>Sie wird im Außenbereich ca. 25 Jahre überstehen</li>
                            </ul>
                        </div>,
                    EN :
                        <div>
                            <ul>
                                <li>Robust wood with strongly scented essential oils</li>
                                <li>The stone pine is treated with stone pine oil once a year</li>
                                <li>It will survive around 25 years outdoors</li>
                            </ul>
                        </div>,
                    NL :
                        <div>
                            <ul>
                                <li>Robuust hout met sterk geurende etherische oliën</li>
                                <li>De steenpijnboom wordt één keer per jaar behandeld met steenpijnolie</li>
                                <li>Het zal buiten ongeveer 25 jaar overleven</li>
                            </ul>
                        </div>,
                    DA : <div>
                            <ul>
                                <li>Robust træ med stærkt duftende æteriske olier</li>
                                <li>Stenfyren behandles med stenfyrolie en gang om året</li>
                                <li>Det vil overleve omkring 25 år udendørs</li>
                            </ul>
                        </div>,
                    HU :
                        <div>
                            <ul>
                                <li>Robusztus fa erős illatú illóolajokkal</li>
                                <li>A kőfenyőt évente egyszer kőfenyőolajjal kezelik</li>
                                <li>Körülbelül 25 évet fog túlélni a szabadban</li>
                            </ul>
                        </div>,
                    SV : <div>
                            <ul>
                                <li>Robust trä med starkt doftande eteriska oljor</li>
                                <li>Sten tall behandlas med sten tall olja en gång om året</li>
                                <li>Det kommer att överleva cirka 25 år utomhus</li>
                            </ul>
                        </div>,
                    RU :
                        <div>
                            <ul>
                                <li>Прочная древесина с сильным запахом эфирных масел</li>
                                <li>Кедр обрабатывают кедровым маслом один раз в год.</li>
                                <li>Он прослужит около 25 лет на открытом воздухе.</li>
                            </ul>
                        </div>
                }
            }
        ]
    },
    root : {
        label : {
            DE : 'Fuß',
            EN : 'Root',
            NL : 'Voet',
            DA : 'Fod',
            HU : 'Láb',
            SV : 'Fot',
            RU : 'Опорная часть'
        },
        options : [
            {
                imageName : 'FussRund',
                name : {
                    DE : 'Standfuß',
                    EN : 'Pedestal',
                    NL : 'Voet',
                    DA : 'Piedestal',
                    HU : 'Szabvány láb',
                    SV : 'Fot',
                    RU : 'Диск'
                },
                description : {
                    DE : 'Auf dem runden Standfuß mit dem Durchmesser von 90cm, steht SunDivan auch bei einseitiger Belastung fest und stabil und ist dennoch im Raum bewegbar.',
                    EN : 'On the round pedestal (90cm in diameter), your SunDivan is stable even under one-sided load but still remains mobile.',
                    NL : 'Op de ronde voet met een doorsnede van 90 cm. staat de Sundivan ook bij een eenzijdige belasting vast en stabiel en is nog steeds draaibaar.',
                    DA : 'Med den runde piedestal er din SunDivan-lounge stabil (diameter: 90cm). Selv når vægten kun er på den ene side af loungen, forbliver den stadig mobil.',
                    HU : 'A 90 cm átmérőjű kerek alapon a SunDivan még egyoldalú terhelés esetén is stabil és mozgatható marad.',
                    SV : 'SunDivan står mycket stadigt och stabilt men är ändå rörlig – även vid ensidig belastning – på den runda foten som är 90 cm i diameter.',
                    RU : 'На массивном диске диаметром 90 см, SunDivan устойчив и стабилен, даже если лежать только на одной стороне. Круглая опорная часть делает SunDivan мобильным и позволяет установить его в любом удобном для Вас месте.'
                }
            }, {
                imageName : 'FussQuadratisch',
                name : {
                    DE : 'Zum Einbetonieren',
                    EN : 'Set in concrete',
                    NL : 'Te cementeren',
                    DA : 'Installering i grund',
                    HU : 'Lebetonozható láb',
                    SV : 'Gjuta Fast',
                    RU : 'Опора квадратная'
                },
                description : {
                    DE : 'Für die Nutzung im öffentlichen Bereich empfiehlt sich den Standfuß von SunDivan einzubetonieren. Wir liefern SunDivan entsprechend mit einem Anker für das Betonfundament. Wahlweise ist das Schrauben auf ein vorhandenes Fundament möglich.',
                    EN : 'For use in public we recommend to set the pedestal in concrete. For that we\'d deliver SunDivan with an anchor for the concrete foundation. Alternatively it is possible to screw SunDivan onto an available foundation.',
                    NL :
                        <div>
                            <p>Bevestigen op een betonfundament</p>
                            <p>Voor het gebruik in openbare ruimten is het advies de voet van de Sundivan te bevestigen
                                d.m.v. verankering op een (bestaand) betonfundament.</p>
                        </div>,
                    DA : 'Til brug i det offentlige anbefaler vi at installere piedestalen i et fundament. Til det, vil vi sende SunDivan med et anker som er egnet til fast grund. Muligt alternativ kan være at skrue SunDivan ind til et allerede eksisterende fundament.',
                    HU : 'A nyilvános helyeken történő használatra ajánlott a SunDivan alapjainak betonba ágyazása. Ennek megfelelően a SunDivan-t az ehhez szükséges horgonnyal szállítjuk. Opcionálisan a csavarral való rögzítés is lehetséges egy már meglévő alaphoz.',
                    SV : 'Om SunDivanen ska stå på samma plats hela tiden rekommenderar vi att den gjuts fast på ett betongfundament. Foten med ankare möjliggör även att SunDivan kan skruvas fast på ett redan befintligt fundament.',
                    RU : 'Для использования SunDivan‘a в общественных местах рекомендуется монтировать опорную часть на бетонный фундамент. Такая модель SunDivan‘a поставляется со специальными анкерами.'
                }
            }
        ]
    },
    cushion : {
        label : {
            DE : 'Polsterauflage',
            EN : 'Cushion',
            NL : 'Kussen',
            DA : 'Pude',
            HU : 'Szivacs feltét',
            SV : 'Dyna',
            RU : 'Матрас-накидка'
        },
        common : {
            name : {
                DE : 'Polsterauflage mit Reißverschluss',
                EN : 'Zippered cushion cover',
                NL : 'Kussen met ritssluiting',
                DA : 'Pude',
                HU : 'Szivacs feltét',
                SV : 'Dyna',
                RU : 'Мягкий матрас-накидка'
            },
            description : {
                DE :
                    <div>
                        <ul>
                            <li>Wird an allen vier Ecken durch Laschen befestigt</li>
                            <li>Kein Verrutschen!</li>
                            <li>Beidseitig verwendbar</li>
                            <li>Inkl. 2 Kopfstützen</li>
                            <li>Kopfstützen durch Gewichte Höhenverstellbar</li>
                            <li>Farbe: anthrazit</li>
                        </ul>
                    </div>,
                EN :
                    <div>
                        <ul>
                            <li>Firmly folded around all 4 corners</li>
                            <li>Does not slip away!</li>
                            <li>Usable on both sides</li>
                            <li>Including 2 head-sets</li>
                            <li>Head-sets are adjustable in height</li>
                            <li>Color: anthracite (dark-grey)</li>
                        </ul>
                    </div>,
                NL :
                    <div>
                        <ul>
                            <li>Op alle 4 de hoeken met vouwrand bevestigd</li>
                            <li>Glijdt niet weg!</li>
                            <li>Tweezijdig bruikbaar</li>
                            <li>Inclusief 2 kopsteunen</li>
                            <li>Kopsteunen middels gewichten in hoogte verstelbaar</li>
                            <li>Kleur: antracietgrijs</li>
                        </ul>
                    </div>,
                DA :
                    <div>
                        <ul>
                            <li>Fastgøres af små lommer på alle fire hjørner</li>
                            <li>Glider ikke!</li>
                            <li>Kan bruges på begge sider</li>
                            <li>Betræk med lynlås</li>
                            <li>Inkl. to nakkestøttere med vægt, til justerbar højde</li>
                            <li>Farve: Antracit</li>
                        </ul>
                    </div>,
                HU : 'A leírás szövege folyamatban van...',
                SV : 'Beskrivningstekst pågår...',
                RU :
                    <div>
                        <ul>
                            <li>крепится на четырёх углах с помощью карманов</li>
                            <li>не сползает</li>
                            <li>используются обе стороны</li>
                            <li>подушки-валики</li>
                            <li>положение подушек фиксируется по высоте</li>
                            <li>цвет: антрацит</li>
                        </ul>
                    </div>
            }
        },
        options : [
            {
                imageName : 'Basis',
                name : {
                    DE : 'Ohne Polsterauflage',
                    EN : 'Without cushion',
                    NL : 'Zonder opvulling',
                    DA : 'Uden polstring',
                    HU : 'Párnázás nélkül',
                    SV : 'Utan padding',
                    RU : 'Без матраса'
                }
            }, {
                imageName : 'BasisPolsterauflage',
                name : {
                    DE : 'Mit Polsterauflage',
                    EN : 'With Cushion',
                    NL : 'Met kussen',
                    DA : 'Med pudeplade',
                    HU : 'Párnával',
                    SV : 'Med kudde',
                    RU : 'С матрасом'
                }
            }
        ]
    },
    table : {
        label : {
            DE : 'Tisch',
            EN : 'Table',
            NL : 'Tafel',
            DA : 'Bord',
            HU : 'Asztal',
            SV : 'Bord',
            RU : 'Столик'
        },
        common : {
            name : {
                DE : 'Tisch',
                EN : 'Table',
                NL : 'Tafel',
                DA : 'Bord',
                HU : 'Asztal',
                SV : 'Bord',
                RU : 'Столик'
            },
            description : {
                DE : 'Der kleine Tisch besteht aus einem Edelstahlrahmen, welcher ebenfalls pulverbeschichtet ist. Die Füllung besteht aus den selben Holzlamellen wie die, die auch für die Liegefläche verwendet wurden. Man kann die Richtung des Tisches ändern oder ihn durch ein Schraubverschlusssystem leicht hin- und abmontieren.',
                EN : 'The table is made of a stainless steel frame, which is powder-coated as well. The panel consists of the same wooden slats as those used for the sunbed. You can change directions of the table, as well as install or remove it easily, with a screw top system.',
                NL : 'De kleine tafel is gemaakt van rvs en daarna gemoffeld. De houtsoort is gelijk aan het materiaal waar de ligstoel van gemaakt is. De draairichting van de tafel kan aangepast worden door aan de schroefknop te draaien en weer vast te zetten.',
                DA : 'Bordet er lavet af en rustfri stålramme, som blandt andet også er pulverbelagt. Panelet består af samme type træ, der er anvendt til loungen. Du kan ændre retningen af bordet, således at den passer perfekt til dig. Ligeså let er det at installere bordet og fjerne det igen med et skruesystem.',
                HU : 'A kis asztal rozsdamentes acél keretből áll, amely szintén porszórt. Lapja ugyanabból a fafajtából áll, mint a fekvő felülethez. Oldalirányban forgatható, egyszerűen eltávolítható vagy rögzíthető egy csavaros rendszerrel.',
                SV : 'Det lilla bordet består av en pulverlackerad ram i rostfritt stål. Själva skivan görs i samma träart som stolarna (Douglasgran, Cederträ) Det är enkelt att montera (skruvsystem) bordet som går att flytta i olika riktningar.',
                RU : 'Рама поворотного столика выполнена из нержавеющей стали с полимерным покрытием. Деревянные панели столика и бруски шезлонга всегда выполняются из одного сорта дерева. Столик можно приблизить к себе или отодвинуть, он легко устанавливается и снимается.'
            }
        },
        options : [
            {
                imageName : 'Basis',
                name : {
                    DE : 'Ohne Tisch',
                    EN : 'Without table',
                    NL : 'Zonder een tafel',
                    DA : 'Uden et bord',
                    HU : 'Táblázat nélkül',
                    SV : 'Utan ett bord',
                    RU : 'Без столика'
                }
            }, {
                imageName : 'TischVorne',
                name : {
                    DE : 'Tisch vorne',
                    EN : 'Table frontward',
                    NL : 'Tafel vooraan',
                    DA : 'Tabel foran',
                    HU : 'Asztal előtt',
                    SV : 'Bord framför',
                    RU : 'Столик слева'
                }
            }, {
                imageName : 'TischHinten',
                name : {
                    DE : 'Tisch hinten',
                    EN : 'Table backward',
                    NL : 'Achter tafel',
                    DA : 'Bagbord',
                    HU : 'Hátsó asztal',
                    SV : 'Bakbord',
                    RU : 'Столик справа'
                }
            }, {
                imageName : 'TischVorneHinten',
                name : {
                    DE : 'Tisch vorne & hinten',
                    EN : 'Table frontward & backward',
                    NL : 'Tafel voor en achter',
                    DA : 'Bord foran og bagside',
                    HU : 'Asztali elöl és hátul',
                    SV : 'Bord fram och bak',
                    RU : 'Столик с двух сторон'
                }
            }
        ]
    },
    parasolHolder : {
        label : {
            DE : 'Sonnenschirmhalterung',
            EN : 'Parasol holder',
            NL : 'Parasolhouder',
            DA : 'Parasolholder',
            HU : 'Napernyőtartó',
            SV : 'Parasollhållare',
            RU : 'Крепление зонта'
        },
        common : {
            name : {
                DE : 'Sonnenschirmhalterung',
                EN : 'Parasol holder',
                NL : 'Parasolhouder',
                DA : 'Parasolholder',
                HU : 'Napernyőtartó',
                SV : 'Parasollhållare',
                RU : 'Крепление зонта'
            },
            description : {
                DE : 'Geeignet für jeden handelsüblichen Sonnenschirm mit dem Stammdurchmesser von bis zu 30mm. Durch einen Schraubverschlusssystem, kann der Sonnenschirm leicht hin- und abmontiert werden.',
                EN : 'Suitable for any customary parasol with a pole diameter of up to 30mm. The parasol can be installed or removed easily utilizing a screw top system.',
                NL : 'Geschikt voor elke normaal in de handel verkrijgbare parasol met een doorsnede tot en met 30 mm. Door een schroefsysteem kan de parasol gedraaid c.q. verwijderd worden.',
                DA : 'Egnet til enhver sædvanlig parasol med en diameter på 30mm. Parasollen kan installeres eller fjernes nemt ved hjælp af et skruesystem.',
                HU : 'Minden szabványos 30 mm-es átmérőjű napernyőhöz. Csavaros rögzítő rendszerrel a napernyő könnyedén be- és kivehető.',
                SV : 'Passar till alla vanliga solparasoller med rördiameter under 30mm. Med ett enkelt skruvsystem monteras parasollet på SunDivan.',
                RU : 'Подходит для любого стандартного зонта с диаметром стержня до 30 мм. Зонт легко устанавливается и снимается.'
            }
        },
        options : [
            {
                imageName : 'Basis',
                name : {
                    DE : 'Ohne Sonnenschirmhalterung',
                    EN : 'Without parasol holder',
                    NL : 'Zonder parasolhouder',
                    DA : 'Uden parasolholder',
                    HU : 'Napernyőtartó nélkül',
                    SV : 'Utan parasollhållare',
                    RU : 'Без держателя'
                }
            }, {
                imageName : 'Sonnenschirmhalterung',
                name : {
                    DE : 'Mit Sonnenschirmhalterung',
                    EN : 'With parasol holder',
                    NL : 'Met parasolhouder',
                    DA : 'Med parasolholder',
                    HU : 'Napernyőtartóval',
                    SV : 'Med parasollhållare',
                    RU : 'С держателем'
                }
            }
        ]
    },
    locker : {
        label : {
            DE : 'Schließfach',
            EN : 'Locker',
            NL : 'Kluis',
            DA : 'Skab',
            HU : 'Széf',
            SV : 'Kassaskåp',
            RU : 'Сейф'
        },
        common : {
            name : {
                DE : 'Schließfach',
                EN : 'Locker',
                NL : 'Kluis',
                DA : 'Skab',
                HU : 'Széf',
                SV : 'Kassaskåp',
                RU : 'Сейф'
            },
            description : {
                DE : 'Unser erweitertes Modell mit integrierten, geräumigem Schließfach und Münzschlosssystem, ist für den Gebrauch in öffentlichen Bäderbetrieben sehr gut geeignet. Gäste können mit einem sicheren Gefühl ihre Wertsachen an ihrem Platz zurücklassen.',
                EN : 'Our extended sunbed with an integrated locker and coin operated lock system works awesome for use in public pools. Guests can leave their valuables at the place feeling perfectly safe.',
                NL : 'Ons model met geïntegreerde ruime kluis en uitgevoerd met muntjessysteem is uitermate geschikt voor openbare zwembaden. Gasten kunnen met een veilig gevoel hun waardevolle spullen hier achterlaten.',
                DA : 'Vores udvidet lounge med et integreret skab og låsesystem med møntadgang, gør det muligt for gæsterne at gemme sine ejendele sikkert i offentlige badeanstalter.',
                HU : 'Kibővített modellünk integrált, tágas szekrény- és érmezáró rendszerrel kiválóan alkalmas nyilvános fürdőhelyeken való használatra. A vendégek értékeiket nyugodtan helyükön hagyhatják.',
                SV : 'Vår utvidgade modell med intregrerat kassaskåp (myntlås) lämpar sig för offentliga platser såsom badhus, spa mm. Era gäster kan lugnt lämna sina värdesaker i kassaskåpet.',
                RU : 'Наша модель со встроенным, вместительным сейфом отлично подходит для использования в бассейнах и аквапарках. Гости могут с полной уверенностью оставлять все ценные вещи на своём месте.'
            }
        },
        options : []
    }
};

export default selections;
